<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">

      </template>
    </side-bar>
    <div class="main-panel">

      <div class="area-box">
        <div class="NotifyArea"> SELECT AREA</div>
      </div>
      <button class="area-1" @click="joinArea">
        <div class=" areaname">
          ALL AREA
        </div>
      </button>
    </div>
  </div>
</template>
<style lang="scss">
.area-box {
  width: 100%;
  height: 80px;
  background: #2dce89;
  border-radius: 10px;
  position: relative;
}

.area-1 {
  margin-top: 20px;
  width: 54%;
  background: linear-gradient(130deg, rgb(255, 255, 255) 24%, rgb(33, 39, 48)5%);
  position: relative;
  border-radius: 10px;
  height: 80px;
}

.area-1:hover {
  background: linear-gradient(130deg, rgb(255, 255, 255) 24%, rgb(33, 39, 48)5%);
  box-shadow: 0 3px 10px rgba(0, 255, 60, 0.2);
}

.areaname {
  text-align: center;
  padding: 15px;
  font-size: 30px;
  color: #fff;
}

.NotifyArea {
  text-align: center;
  padding-top: 5px;
  font-size: 50px;
}
</style>
<script>

export default {
  components: {

  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    joinArea() {
      this.$router.push({ name: 'dashboard' });
    }
  }
};
</script>
